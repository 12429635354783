import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast'
import { Modal } from "flowbite";
import { useNavigate } from "react-router-dom";

const Searchcard = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [scratchcards, setScratchCards] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const [barcodeFront, setBarcodeFront] = useState('');
    const [barcodeBack, setBarcodeBack] = useState('');
    const [isVerified, setIsVerified] = useState('');
    const [testPassed, setTestPassed] = useState('');
    const [prizeClaim, setPrizeClaim] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [shading, setShading] = useState('');
    const [id, setId] = useState('');

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    // Get "user" module permissions
    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        barcodeFront: "",
        barcodeBack: "",
        status: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    useEffect(() => {
        const getScratchCards = async () => {

            try {
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/scratchcard/get-scratchcard?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setScratchCards(res.data.data.scratchcards);

                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });
            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Scratch Cards found.");
                }
            }
        }
        getScratchCards();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'scratchcards'
            ));
        }
    }, [filters, refreshKey]);

    // get single admin data
    const getScratchcardData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/scratchcard/get-scratchcard/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setId(id);
            setBarcodeFront(res.data.scratchcard.barcodeFront);
            setBarcodeBack(res.data.scratchcard.barcodeBack);
            if (res.data.scratchcard.isVerified === true) {
                setIsVerified("1");
            } else if (res.data.scratchcard.isVerified === false) {
                setIsVerified("0")
            } else {
                setIsVerified("");
            }
            if (res.data.scratchcard.testPassed === true) {
                setTestPassed("1");
            } else if (res.data.scratchcard.testPassed === false) {
                setTestPassed("0")
            } else {
                setTestPassed("");
            }
            setPrizeClaim(res.data.scratchcard.prizeClaim);
            setSerialNumber(res.data.scratchcard.serialNumber);
            setShading(res.data.scratchcard.shading);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateScratchCard = async (e) => {
        e.preventDefault();
        const update_id = id;

        const postData = {
            "barcodeFront": barcodeFront,
            "barcodeBack": barcodeBack,
            "isVerified": isVerified,
            "testPassed": testPassed,
            "serialNumber": serialNumber,
            "prizeClaim": prizeClaim,
            "shading": shading,
        };


        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/scratchcard/update-scratchcard/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteScratchCard = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/scratchcard/delete-scratchcard/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);



        } catch (err) {
            console.error(err);
        }
    }

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/scratchcard/update-scratchcard/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    // status div
    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return (
                    <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p>
                );
            case 2:
                return (
                    <p className="bg-yellow-100 text-sm text-yellow-800 px-3 py-1 rounded-full md:w-full text-center">
                        Claimed
                    </p>
                );
            default:
                return (
                    <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>
                );
        }
    }

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">

                <div className="w-full mt-5 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Previous Search Result</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        {/* Filters UI */}
                        <div className="mb-4 md:flex gap-4">
                            <input
                                type="text"
                                placeholder="Search by Barcode Front"
                                value={filters.barcodeFront}
                                onChange={(e) => handleFilterChange("barcodeFront", e.target.value)}
                                className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                            />
                            <input
                                type="text"
                                placeholder="Search by Barcode Back"
                                value={filters.barcodeBack}
                                onChange={(e) => handleFilterChange("barcodeBack", e.target.value)}
                                className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                            />

                            <select
                                value={filters.status}
                                onChange={(e) => handleFilterChange("status", e.target.value)}
                                className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                            >
                                <option value="">All Statuses</option>
                                <option value="1">Active</option>
                                <option value="2">Claimed</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Barcode Front
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Barcode Back
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Allocated to
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    {permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">
                                            Toggle Status
                                        </th>}
                                    {permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    scratchcards.length > 0 ? (
                                        permissionModule.canRead ? scratchcards.map((scratchcard) => (
                                            <tr key={scratchcard.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {scratchcard.barcodeFront}
                                                </th>
                                                <td className="px-6 py-4">{scratchcard.barcodeBack}</td>
                                                <td className="px-6 py-4">{formatDate(scratchcard.createdAt)}</td>
                                                <td className="px-6 py-4">{scratchcard.Batch.AdminUser.name}</td>
                                                <td className="px-6 py-4">{scratchcard.User.name}</td>
                                                <td className="px-6 py-4">{renderStatus(scratchcard.status)}</td>
                                                {/* <td className="px-6 py-4">{scratchcard.status === 1 ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>}</td> */}
                                                {
                                                    permissionModule.canUpdate &&
                                                    <td className="text-center">
                                                        {
                                                            scratchcard.status === 1 || scratchcard.status === 2 ? <button type="button" onClick={() => updateStatus(scratchcard.id, 0)} className="bg-red-600 text-white px-3 py-1 rounded-full">Disable</button>
                                                                : <button type="button" onClick={() => updateStatus(scratchcard.id, 1)} className="bg-green-600 text-white px-3 py-1 rounded-full">Enable</button>
                                                        }
                                                    </td>
                                                }
                                                {
                                                    permissionModule.canUpdate &&
                                                    <td className="px-6 py-4">
                                                        <button type="button" onClick={() => getScratchcardData(scratchcard.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                    </td>
                                                }

                                            </tr>
                                        )) : "Access Denied") :
                                        (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No Scratch Cards found.
                                                </td>
                                            </tr>
                                        )
                                }


                            </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>

                {/* edit modal */}
                <div
                    id="edit-modal"
                    tabIndex={-1}
                    className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => {
                                    const modalElement = document.getElementById("edit-modal");
                                    if (modalElement) {
                                        const modal = new Modal(modalElement);
                                        modal.hide();
                                    }
                                }}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4  text-center">
                                <div className=" p-6 bg-white shadow rounded-lg">
                                    <h2 className="text-2xl font-bold text-center mb-6">Edit Scratch Card</h2>
                                    <form onSubmit={handleUpdateScratchCard}>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Barcode Front</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    placeholder="Barcode-Front"
                                                    value={barcodeFront}
                                                    onChange={(e) => setBarcodeFront(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Barcode Back</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    placeholder="Barcode Back"
                                                    value={barcodeBack}
                                                    onChange={(e) => setBarcodeBack(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Verifed</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <select
                                                    id="batchId"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    value={isVerified}
                                                    onChange={(e) => setIsVerified(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT VERIFIED --</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>


                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Test Passed</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <select
                                                    id="batchId"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    value={testPassed}
                                                    onChange={(e) => setTestPassed(e.target.value)}
                                                    required
                                                >
                                                    <option value="" disabled>-- SELECT TEST PASSED --</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Prize Claim</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    placeholder="100"
                                                    value={prizeClaim}
                                                    onChange={(e) => setPrizeClaim(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Serial Number</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    placeholder="[87418, 871987, 98797, 9897]"
                                                    value={serialNumber}
                                                    onChange={(e) => setSerialNumber(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="md:col-span-1">
                                                <label className="block mb-1 font-medium">Shading</label>
                                            </div>
                                            <div className="md:col-span-2">
                                                <input
                                                    type="text"
                                                    className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                    placeholder="15"
                                                    value={shading}
                                                    onChange={(e) => setShading(e.target.value)}
                                                    required
                                                />
                                            </div>

                                        </div>

                                        <div className="mt-5 flex justify-end">



                                            <div>

                                                <button
                                                    type="submit"
                                                    className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const modalElement = document.getElementById("edit-modal");
                                                        if (modalElement) {
                                                            const modal = new Modal(modalElement);
                                                            modal.hide();
                                                        }
                                                    }}
                                                    type="button"
                                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    {
                                        permissionModule.canDelete &&

                                        <div className="flex justify-start ">
                                            <button onClick={() => deleteScratchCard(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                        </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Searchcard;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Faq = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [faqs, setFaqs] = useState([]);

    const navigate = useNavigate();

    const [heading, setHeading] = useState('');
    const [paragraph, setParagraph] = useState('');

    const [id, setId] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    // pagination and search
    const [filters, setFilters] = useState({
        heading: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    // Get all admins data
    useEffect(() => {
        const getFaqs = async (e) => {

            try {
                const queryParams = Object.entries(filters)
                    .filter(([_, value]) => value) // Only include parameters with non-empty values
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join("&");
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/faq/get-faq?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setFaqs(res.data.data.faqs);
                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        // navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Faqs found.");
                }
            }
        }
        getFaqs();

        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'faq'
            ));
        }
    }, [refreshKey, filters]);


    // create admin
    const handleCreateFaq = async (e) => {
        e.preventDefault();

        const postData = {
            "heading": heading,
            "paragraph": paragraph,
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/faq/create-faq", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getFaqData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/faq/get-faq/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setHeading(res.data.faq.heading);
            setParagraph(res.data.faq.paragraph);
            setId(id);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateFaq = async (e) => {
        e.preventDefault();
        const update_id = id;



        const postData = {
            "heading": heading,
            "paragraph": paragraph,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/faq/update-faq/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteFaq = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/faq/delete-faq/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);




        } catch (err) {
            console.error("Error in getSales:", err);
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };


    return (
        <div className="bg-gray-100">
            <div className="py-20 p-4 sm:ml-64">
                <div className="md:flex justify-between py-5">

                    {
                        permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add New Faq</button>
                    }
                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search Faq"
                            value={filters.heading}
                            onChange={(e) => handleFilterChange("heading", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />

                    </div>
                </div>


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl md:text-4xl text-black font-bold">Faq</h1>

                        </div>

                    </div>

                    <div className="mt-5">
                        {
                            faqs.length > 0 ? (
                                permissionModule.canRead ? faqs.map((faq, index) => (
                                    <div key={index} className="border-b border-gray-200">
                                        <h2>
                                            <button
                                                type="button"
                                                className={`flex items-center justify-between w-full py-7 font-medium text-left text-black`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                <span>{faq.heading}</span>
                                                <svg
                                                    className={`w-6 h-6 transition-transform ${activeIndex === index ? 'rotate-180' : ''}`}
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div className={`${activeIndex === index ? 'block' : 'hidden'}`}>
                                            <div className="py-5 text-black text-left">{faq.paragraph}</div>
                                            {
                                                permissionModule.canUpdate &&
                                                <div className=" py-4 mt-5 flex">
                                                    <button type="button" onClick={() => getFaqData(faq.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                    {permissionModule.canDelete && <button onClick={() => deleteFaq(id)} className="bg-red-600 text-white px-5 py-2 rounded-full ml-5">Remove</button>}
                                                </div>
                                            }
                                            {/* {
                                                permissionModule.canDelete ?
                                                    <div className="px-6 py-4 mt-5 flex">
                                                    </div> : ""
                                            } */}
                                        </div>
                                    </div>
                                )) : "Access Denied") :
                                (
                                    <div>
                                        <div colSpan="7" className="text-center py-4">
                                            No faqs found.
                                        </div>
                                    </div>
                                )
                        }
                    </div>

                    {/* Pagination */}
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={() => handlePageChange(filters.page - 1)}
                            disabled={filters.page === 1}
                            className="text-white py-1 px-4 bg-black rounded-full mx-1"
                        >
                            Previous
                        </button>
                        <span className="px-4 py-2 mx-1 font-medium">
                            Page {pagination.currentPage} of {pagination.totalPages}
                        </span>
                        <button
                            onClick={() => handlePageChange(filters.page + 1)}
                            disabled={filters.page === pagination.totalPages}
                            className="text-white py-1 px-4 bg-black rounded-full mx-1"
                        >
                            Next
                        </button>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Create new Faq</h2>
                                        <form onSubmit={handleCreateFaq}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Heading</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Heading"
                                                        value={heading}
                                                        onChange={(e) => setHeading(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Paragraph</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="textarea"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Paragraph"
                                                        value={paragraph}
                                                        onChange={(e) => setParagraph(e.target.value)}
                                                        required

                                                    />
                                                </div>



                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit Faq</h2>
                                        <form onSubmit={handleUpdateFaq}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">
                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Heading</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Heading"
                                                        value={heading}
                                                        onChange={(e) => setHeading(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Paragraph</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="textarea"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Paragraph"
                                                        value={paragraph}
                                                        onChange={(e) => setParagraph(e.target.value)}
                                                        required

                                                    />
                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Faq;
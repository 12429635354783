import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Users = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [users, setUsers] = useState([]);

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');

    const [refreshKey, setRefreshKey] = useState(0);

    const navigate = useNavigate();


    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setpermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        name: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    useEffect(() => {
        const getUsers = async () => {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user?${queryParams}&isSuperAgent=2`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const filteredUsers = res.data.data.users;

                setUsers(filteredUsers);

                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Users Found.");
                }
            }
        }
        getUsers();


        if (permissions) {

            setpermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, [refreshKey, filters]);



    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    // get single admin data
    const getUserData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setFullname(res.data.user.name);
            setEmail(res.data.user.email);
            setContact(res.data.user.phone);
            setLocation(res.data.user.City.name + ', ' + res.data.user.City.State.name);
            setDate(formatDate(res.data.user.createdAt))

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/user/update-user/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };



    return (
        <div className="bg-gray-100">
            <div className="py-20 p-4 sm:ml-64">
                <div className="md:flex justify-start py-5">

                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Name"
                            value={filters.name}
                            onChange={(e) => handleFilterChange("name", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />

                    </div>
                </div>


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Users</h1>
                            <h1 className="text-md text-gray-400">This is a list of latest Players</h1>
                        </div>

                    </div>

                    <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Name</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Email</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Contact</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Location</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Total Games Played</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Total Referrals</th>
                                    {permissionModule?.canRead && <th scope="col" className="px-6 py-3 whitespace-normal break-words">View</th>}
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Status</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Ban/Unban</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ? (
                                    permissionModule?.canRead ? users.map((user) => (
                                        <tr key={user.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-normal break-words dark:text-white">{user.name}</th>
                                            <td className="px-6 py-4 whitespace-normal break-words">{user.email}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{user.phone}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{user.City ? (user.City.name + ', ' + user.City.State.name) : ""}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">0</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">0</td>
                                            {permissionModule?.canUpdate && (
                                                <td className="px-6 py-4 whitespace-normal break-words">
                                                    <button type="button" onClick={() => getUserData(user.id)} className="text-white py-1 px-4 bg-black rounded-full">View</button>
                                                </td>
                                            )}
                                            <td className="px-6 py-4 whitespace-normal break-words">
                                                {user.status === 1 ? (
                                                    <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p>
                                                ) : (
                                                    <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>
                                                )}
                                            </td>
                                            <td className="text-center whitespace-normal break-words">
                                                {user.status === 1 ? (
                                                    <button type="button" onClick={() => updateStatus(user.id, 0)} className="bg-red-600 text-white px-3 py-1 rounded-full">Ban</button>
                                                ) : (
                                                    <button type="button" onClick={() => updateStatus(user.id, 1)} className="bg-green-600 text-white px-3 py-1 rounded-full">Unban</button>
                                                )}
                                            </td>
                                            
                                        </tr>
                                    )) : "Access Denied"
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4 whitespace-normal break-words">No users found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>


                        {/* Pagination */}
                        <div className="flex justify-center my-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>




                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">User Details</h2>
                                        <form>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>



                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Email</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="email"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Contact Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="number"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Location</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Joined On</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={date}
                                                        onChange={(e) => setDate(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Credits</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="number"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={0}
                                                        // onChange={(e) => setDate(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Games</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="number"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={0}
                                                        // onChange={(e) => setDate(e.target.value)}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* view modal */}
                    <div
                        id="view-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("view-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Serial Number</h2>
                                        <p className="text-xl font-medium m-5" id="serial-number"></p>
                                    </div>

                                    <button
                                        onClick={() => {
                                            const modalElement = document.getElementById("view-modal");
                                            if (modalElement) {
                                                const modal = new Modal(modalElement);
                                                modal.hide();
                                            }
                                        }}
                                        type="button"
                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                    >
                                        Cancel
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users;
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './AuthContext';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSalesManager } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    const token = sessionStorage.getItem('bearer_token');
    const permissions = sessionStorage.getItem('permissions');
    console.log("Running token validation...");

    if (!token && !permissions) {
      console.log("Token is missing. Redirecting to login.");
      navigate('/login', { replace: true });
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      // Increase currentTime by 10 hours
      // const adjustedCurrentTime = currentTime + 10 * 60 * 60;
      const adjustedExpTime = decodedToken.exp - 32400;

      // console.log("Decoded Token:", decodedToken);
      // console.log("Expiry:", currentTime >= adjustedExpTime);
      // console.log("Current Time:", new Date(Date.now()));
      // console.log("Issued Time:", new Date(decodedToken.iat * 1000));
      // console.log("Token Expiration Time (UTC):", new Date((adjustedExpTime * 1000) ));


      if (currentTime >= adjustedExpTime) {
        console.log("Token is expired. Redirecting to login.");
        sessionStorage.removeItem('bearer_token');
        navigate('/login', { replace: true });
      } else {
        console.log("Token is valid. Access granted.");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      sessionStorage.removeItem('bearer_token');
      navigate('/login', { replace: true });
    }

    // List of routes accessible only to Sales Manager
    const salesManagerRoutes = ["/", "/sales", "/kiosk-sales",`/agent-profile/${id}`, "/add-superagent", "/add-kioskowner"];

    if (isSalesManager && !salesManagerRoutes.includes(location.pathname)) {
      console.log("Unauthorized access attempt. Redirecting to home.");
      navigate("/", { replace: true });
      
    }
  }, [navigate]);


  return <Outlet />;
};

export default PrivateRoute;

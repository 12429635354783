import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";


const CountryGames = () => {

    const params = useParams();

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const times = Array.from({ length: 12 }, (_, i) => `${i + 1}:00`);
    const periods = ["AM", "PM"];

    const [selectedDay, setSelectedDay] = useState(days[0]);
    const [selectedTime, setSelectedTime] = useState(times[0]);
    const [selectedPeriod, setSelectedPeriod] = useState(periods[0]);

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [gameTypes, setGameTypes] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    // const [drawTime, setDrawTime] = useState('');
    const [drawFrequency, setDrawFrequency] = useState('');
    const [winningAmount, setWinningAmount] = useState('');
    const [creditsOne, setCreditsOne] = useState('');
    const [creditsTwo, setCreditsTwo] = useState('');
    const [creditsThree, setCreditsThree] = useState('');
    const [winningOne, setWinningOne] = useState('');
    const [winningTwo, setWinningTwo] = useState('');
    const [winningThree, setWinningThree] = useState('');
    const [winningSixMatch, setWinningSixMatch] = useState('');
    const [winningFiveMatch, setWinningFiveMatch] = useState('');
    const [winningFourMatch, setWinningFourMatch] = useState('');
    const [winningThreeMatch, setWinningThreeMatch] = useState('');

    const [id, setId] = useState('');
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }


    }, []);

    // create country
    const handleCreateGameType = async (e) => {
        e.preventDefault();

        const time = drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening"
            ? `${selectedDay} ${selectedTime} ${selectedPeriod}`
            : `${selectedTime} ${selectedPeriod}`;

        const postData = {
            "countryId": params.id,
            "name": name,
            "type": type,
            "drawFrequency": drawFrequency,
            "drawTime": time,
            "winningAmount": winningAmount,
            "creditsRequiredLevelOne": creditsOne,
            "creditsRequiredLevelTwo": creditsTwo,
            "creditsRequiredLevelThree": creditsThree,
            "winningPercentageSixMatch": winningSixMatch,
            "winningPercentageFiveMatch": winningFiveMatch,
            "winningPercentageFourMatch": winningFourMatch,
            "winningPercentageThreeMatch": winningThreeMatch,
            "winningPercentageForLevelOne": winningOne,
            "winningPercentageForLevelTwo": winningTwo,
            "winningPercentageForLevelThree": winningThree,
        };


        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/game-type/create-game-type", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    useEffect(() => {
        const getGameTypes = async () => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/game-type/get-game-types?countryId=${params.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setGameTypes(res.data.gameTypes);

            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getGameTypes();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'gameType'
            ));
        }

    }, [refreshKey]);



    // get single gameType data
    const getGameTypeData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/game-type/get-game-types/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setName(res.data.gameType.name);
            setType(res.data.gameType.type);
            setDrawFrequency(res.data.gameType.drawFrequency);
            if (res.data?.gameType?.drawTime) {
                if(res.data.gameType.drawFrequency !== "morning" && res.data.gameType.drawFrequency !== "noon" && res.data.gameType.drawFrequency !== "evening"){
                    const [day, time, period] = res.data.gameType.drawTime.split(" ");
                    setSelectedDay(day);
                    setSelectedTime(time);
                    setSelectedPeriod(period);
                }else{
                    const [time, period] = res.data.gameType.drawTime.split(" ");
                    setSelectedTime(time);
                    setSelectedPeriod(period);
                }
            }
            setWinningAmount(res.data.gameType.winningAmount);
            setCreditsOne(res.data.gameType.creditsRequiredLevelOne);
            setCreditsTwo(res.data.gameType.creditsRequiredLevelTwo);
            setCreditsThree(res.data.gameType.creditsRequiredLevelThree);
            setWinningSixMatch(res.data.gameType.winningPercentageSixMatch);
            setWinningFiveMatch(res.data.gameType.winningPercentageFiveMatch);
            setWinningFourMatch(res.data.gameType.winningPercentageFourMatch);
            setWinningThreeMatch(res.data.gameType.winningPercentageThreeMatch);
            setWinningOne(res.data.gameType.winningPercentageForLevelOne);
            setWinningTwo(res.data.gameType.winningPercentageForLevelTwo);
            setWinningThree(res.data.gameType.winningPercentageForLevelThree);
            setId(id);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    const handleUpdateGameType = async (e) => {
        e.preventDefault();
        const update_id = id;

        const time = drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening"
            ? `${selectedDay} ${selectedTime} ${selectedPeriod}`
            : `${selectedTime} ${selectedPeriod}`;

        const postData = {
            "name": name,
            "type": type,
            "drawFrequency": drawFrequency,
            "drawTime": time,
            "winningAmount": winningAmount,
            "creditsRequiredLevelOne": creditsOne,
            "creditsRequiredLevelTwo": creditsTwo,
            "creditsRequiredLevelThree": creditsThree,
            "winningPercentageSixMatch": winningSixMatch,
            "winningPercentageFiveMatch": winningFiveMatch,
            "winningPercentageFourMatch": winningFourMatch,
            "winningPercentageThreeMatch": winningThreeMatch,
            "winningPercentageForLevelOne": winningOne,
            "winningPercentageForLevelTwo": winningTwo,
            "winningPercentageForLevelThree": winningThree,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/game-type/update-game-type/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    const deleteGameType = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/game-type/delete-game-type/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);




        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/game-type/update-game-type/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    return (
        <div className="bg-gray-100">
            <div className="py-20 p-4 sm:ml-64">
                <div className="md:flex justify-between py-5">

                    {
                        // permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add New Game</button>
                    }

                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Name"
                            // value={filters.name}
                            // onChange={(e) => handleFilterChange("name", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />

                    </div>
                </div>


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Countries - Games</h1>
                            <h1 className="text-xl text-black font-bold">{gameTypes[0]?.Country?.name ? gameTypes[0].Country.name : ''}</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Game</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Type</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Draw Frequency</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Draw Time</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Winning Amount</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Draw</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Winning Numbers</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Status</th>
                                    <th scope="col" className="px-3 py-3 whitespace-normal break-words">Toggle</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Draw Date and Time</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Edit</th>
                                    {/* <th scope="col" className="px-6 py-3 whitespace-normal break-words">Delete</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {gameTypes.length > 0 ? (
                                    permissionModule.canRead ? gameTypes.map((gameType) => (
                                        <tr key={gameType.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">

                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.name}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words capitalize">{gameType.type}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words capitalize">{gameType.drawFrequency}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.drawTime}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.Country.currencySymbol + gameType.winningAmount}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words"><a href={"/draw/" + gameType.id} className="bg-blue-400 text-white rounded-full px-3 py-1">Draw</a></td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.winningNumbers ? gameType.winningNumbers.join(", ") : "N/A"}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.status === 1 ? "On" : "Off"}</td>
                                            <td className="px-3 py-4 whitespace-normal break-words">{gameType.status === 1 ? (
                                                <button type="button" onClick={() => updateStatus(gameType.id, "0")} className="bg-red-600 text-white px-3 py-1 rounded-full">Off</button>
                                            ) : (
                                                <button type="button" onClick={() => updateStatus(gameType.id, 1)} className="bg-green-600 text-white px-3 py-1 rounded-full">On</button>
                                            )}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{gameType.lastDrawDate ? formatDate(gameType.lastDrawDate) : "N/A"}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words"><button type="button" onClick={() => getGameTypeData(gameType.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button></td>
                                            {/* <td className="px-6 py-4 whitespace-normal break-words"><button className="bg-red-600 text-white px-4 py-1 rounded-full">Delete</button></td> */}

                                        </tr>
                                    )) : "Access Denied"
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4 whitespace-normal break-words">No Games Found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>


                        {/* Pagination */}
                        {/* <div className="flex justify-center my-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div
                id="popup-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Add New Game Type</h2>
                                <form onSubmit={handleCreateGameType}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Game</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Game Type</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                                className="w-full mb-1 p-2 border border-gray-300 rounded" required>
                                                <option value="" disabled>-- SELECT GAME TYPE --</option>
                                                <option key="national" value="national">National</option>
                                                <option key="continental" value="continental">Continental</option>
                                            </select>
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Draw Frequency</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                value={drawFrequency}
                                                onChange={(e) => setDrawFrequency(e.target.value)}
                                                className="w-full mb-1 p-2 border border-gray-300 rounded" required>
                                                <option value="" disabled>-- SELECT DRAW FREQUENCY --</option>
                                                <option key="weekly" value="weekly">Weekly</option>
                                                <option key="morning" value="morning">Morning</option>
                                                <option key="noon" value="noon">Noon</option>
                                                <option key="evening" value="evening">Evening</option>
                                            </select>
                                        </div>

                                        {/* Draw date and time  */}
                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Draw Date and Time</label>
                                        </div>
                                        <div className="md:col-span-2 flex space-x-2 items-center">
                                            {/* Day Dropdown */}
                                            {drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening" && (
                                                <select
                                                    className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                    value={selectedDay}
                                                    onChange={(e) => setSelectedDay(e.target.value)}
                                                >
                                                    {days.map((day) => (
                                                        <option key={day} value={day}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            {/* Time Dropdown */}
                                            <select
                                                className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                value={selectedTime}
                                                onChange={(e) => setSelectedTime(e.target.value)}
                                            >
                                                {times.map((time) => (
                                                    <option key={time} value={time}>
                                                        {time}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* AM/PM Dropdown */}
                                            <select
                                                className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                value={selectedPeriod}
                                                onChange={(e) => setSelectedPeriod(e.target.value)}
                                            >
                                                {periods.map((period) => (
                                                    <option key={period} value={period}>
                                                        {period}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* Output */}
                                            <span className="text-lg font-semibold text-gray-800">
                                                {drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening"
                                                    ? `${selectedDay} ${selectedTime} ${selectedPeriod}`
                                                    : `${selectedTime} ${selectedPeriod}`}
                                            </span>
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Amount</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Amount"
                                                value={winningAmount}
                                                onChange={(e) => setWinningAmount(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level One</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level One"
                                                value={creditsOne}
                                                onChange={(e) => setCreditsOne(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level Two</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level Two"
                                                value={creditsTwo}
                                                onChange={(e) => setCreditsTwo(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level Three</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level Three"
                                                value={creditsThree}
                                                onChange={(e) => setCreditsThree(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level One</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level One"
                                                value={winningOne}
                                                onChange={(e) => setWinningOne(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level Two</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level Two"
                                                value={winningTwo}
                                                onChange={(e) => setWinningTwo(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level Three</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level Three"
                                                value={winningThree}
                                                onChange={(e) => setWinningThree(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Six Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Six Match"
                                                value={winningSixMatch}
                                                onChange={(e) => setWinningSixMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Five Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Five Match"
                                                value={winningFiveMatch}
                                                onChange={(e) => setWinningFiveMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Four Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Four Match"
                                                value={winningFourMatch}
                                                onChange={(e) => setWinningFourMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Three Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Three Match"
                                                value={winningThreeMatch}
                                                onChange={(e) => setWinningThreeMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                    </div>



                                    <div className="mt-5 flex justify-between">



                                        <div>

                                            <button

                                                type="submit"
                                                className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const createElement = document.getElementById("popup-modal");
                                                    if (createElement) {
                                                        const create_modal = new Modal(createElement);
                                                        create_modal.hide();
                                                    }
                                                }}
                                                type="button"
                                                className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            {/* edit modal */}
            <div
                id="edit-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const modalElement = document.getElementById("edit-modal");
                                if (modalElement) {
                                    const modal = new Modal(modalElement);
                                    modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Edit Game Type</h2>
                                <form onSubmit={handleUpdateGameType}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Game</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Game Type</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                                className="w-full mb-1 p-2 border border-gray-300 rounded" required>
                                                <option value="" disabled>-- SELECT GAME TYPE --</option>
                                                <option key="national" value="national">National</option>
                                                <option key="continental" value="continental">Continental</option>
                                            </select>
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Draw Frequency</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <select
                                                value={drawFrequency}
                                                onChange={(e) => setDrawFrequency(e.target.value)}
                                                className="w-full mb-1 p-2 border border-gray-300 rounded" required>
                                                <option value="" disabled>-- SELECT DRAW FREQUENCY --</option>
                                                <option key="weekly" value="weekly">Weekly</option>
                                                <option key="morning" value="morning">Morning</option>
                                                <option key="noon" value="noon">Noon</option>
                                                <option key="evening" value="evening">Evening</option>
                                            </select>
                                        </div>

                                        {/* Draw date and time  */}
                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Draw Date and Time</label>
                                        </div>
                                        <div className="md:col-span-2 flex space-x-2 items-center">
                                            {/* Day Dropdown */}
                                            {drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening" && (
                                                <select
                                                    className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                    value={selectedDay}
                                                    onChange={(e) => setSelectedDay(e.target.value)}
                                                >
                                                    {days.map((day) => (
                                                        <option key={day} value={day}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            {/* Time Dropdown */}
                                            <select
                                                className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                value={selectedTime}
                                                onChange={(e) => setSelectedTime(e.target.value)}
                                            >
                                                {times.map((time) => (
                                                    <option key={time} value={time}>
                                                        {time}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* AM/PM Dropdown */}
                                            <select
                                                className="px-4 py-2 rounded-full bg-blue-700 text-white focus:outline-none"
                                                value={selectedPeriod}
                                                onChange={(e) => setSelectedPeriod(e.target.value)}
                                            >
                                                {periods.map((period) => (
                                                    <option key={period} value={period}>
                                                        {period}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* Output */}
                                            <span className="text-lg font-semibold text-gray-800">
                                                {drawFrequency !== "morning" && drawFrequency !== "noon" && drawFrequency !== "evening"
                                                    ? `${selectedDay} ${selectedTime} ${selectedPeriod}`
                                                    : `${selectedTime} ${selectedPeriod}`}
                                            </span>
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Amount</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Amount"
                                                value={winningAmount}
                                                onChange={(e) => setWinningAmount(e.target.value)}
                                                required
                                            />
                                        </div>


                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level One</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level One"
                                                value={creditsOne}
                                                onChange={(e) => setCreditsOne(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level Two</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level Two"
                                                value={creditsTwo}
                                                onChange={(e) => setCreditsTwo(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Credits for Level Three</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Credits for Level Three"
                                                value={creditsThree}
                                                onChange={(e) => setCreditsThree(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level One</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level One"
                                                value={winningOne}
                                                onChange={(e) => setWinningOne(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level Two</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level Two"
                                                value={winningTwo}
                                                onChange={(e) => setWinningTwo(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Level Three</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Level Three"
                                                value={winningThree}
                                                onChange={(e) => setWinningThree(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Six Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Six Match"
                                                value={winningSixMatch}
                                                onChange={(e) => setWinningSixMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Five Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Five Match"
                                                value={winningFiveMatch}
                                                onChange={(e) => setWinningFiveMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Four Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Four Match"
                                                value={winningFourMatch}
                                                onChange={(e) => setWinningFourMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Winning Percentage for Three Match</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="number"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder="Winning Percentage for Three Match"
                                                value={winningThreeMatch}
                                                onChange={(e) => setWinningThreeMatch(e.target.value)}
                                                required
                                            />
                                        </div>

                                    </div>

                                    <div className="mt-5 flex justify-end">



                                        <div>

                                            <button
                                                type="submit"
                                                className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const modalElement = document.getElementById("edit-modal");
                                                    if (modalElement) {
                                                        const modal = new Modal(modalElement);
                                                        modal.hide();
                                                    }
                                                }}
                                                type="button"
                                                className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                            {
                                permissionModule.canDelete &&
                                <div className="flex justify-start ">
                                    <button onClick={() => deleteGameType(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default CountryGames;
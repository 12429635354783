import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Cities = () => {

    const [name, setName] = useState('');
    const [state, setState] = useState('');

    const [refreshKey, setRefreshKey] = useState(0);
    const [id, setId] = useState('');

    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [states, setStates] = useState([]);

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const [cities, setCities] = useState([]);

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        search: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    // Get all admins data
    useEffect(() => {
        const getCities = async (e) => {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/state-city/cities?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setCities(res.data.data);
                setPagination({
                    totalRecords: res.data.totalRecords,
                    currentPage: res.data.currentPage,
                    totalPages: res.data.totalPages,
                    limit: res.data.limit,
                });

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getCities();

        // get all states
        const getStates = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/state-city/states", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });
                setStates(res.data.data);
            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getStates();

        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'location'
            ));
        }
    }, [refreshKey, filters]);

    // create admin
    const handleCreateCity = async (e) => {
        e.preventDefault();

        const postData = {
            name: name,
            stateId: state,
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/state-city/cities", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);

            // Show success notification
            toast.success("City Created Successfully!");

        } catch (err) {
            if (err.response?.status === 400) {
                // Show error notification
                toast.error(err.response.data);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    };


    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getCityData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/state-city/cities/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setName(res.data.city.name);
            setState(res.data.city.stateId);
            setId(res.data.city.id);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateCity = async (e) => {
        e.preventDefault();
        const update_id = id;


        const postData = {
            "name": name,
            "stateId": state,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/state-city/cities/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);


            toast.success("State Updated Successfully!");



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteCity = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/state-city/cities/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);



        } catch (err) {
            console.error("Error in getSales:", err);
        }
    }

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    // Handle search functionality
    const handleStateSearch = async (search) => {
        setStateSearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/state-city/states?search=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setStates(res.data.data);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };


    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <li className="flex-1">
                            <a
                                href="/states"
                                className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-s"
                                aria-current="page"
                            >
                                States
                            </a>
                        </li>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/cities"
                            className="block m-1 p-2 text-center  bg-white text-black hover:text-black"
                        >
                            Cities
                        </a>
                    </li>
                </ul>



                <div className="md:flex justify-between">

                    {
                        permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add new City</button>
                    }

                    {/* Filters UI */}
                    <div className="items-center md:flex my-5 gap-4">
                        <input
                            type="text"
                            placeholder="Search"
                            value={filters.search}
                            onChange={(e) => handleFilterChange("search", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />

                    </div>
                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Cities</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>

                                    <th scope="col" className="px-6 py-3">
                                        City
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        State
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time added
                                    </th>
                                    {
                                        permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                    }

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cities.length > 0 ? (
                                        permissionModule.canRead ? cities.map((city) => (
                                            <tr key={city.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">

                                                <td className="px-6 py-4">{city.name}</td>
                                                <td className="px-6 py-4">{city.State.name}</td>
                                                <td className="px-6 py-4">{formatDate(city.createdAt)}</td>
                                                {
                                                    permissionModule.canUpdate &&
                                                    <td className="px-6 py-4">
                                                        <button type="button" onClick={() => getCityData(city.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                    </td>
                                                }

                                            </tr>
                                        )) : "Access Denied") :
                                        (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No Cities found.
                                                </td>
                                            </tr>
                                        )
                                }


                            </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Create new City</h2>
                                        <form onSubmit={handleCreateCity}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter City Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="City Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select State</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search state"
                                                        value={stateSearchTerm}
                                                        onChange={(e) => handleStateSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={state}
                                                        onChange={(e) => setState(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT STATE --</option>
                                                        {
                                                            states.map((state) => (
                                                                <option key={state.id} value={state.id}>{state.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>


                                            </div>


                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit City</h2>
                                        <form onSubmit={handleUpdateCity}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter City Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="State Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select State</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search state"
                                                        value={stateSearchTerm}
                                                        onChange={(e) => handleStateSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={state}
                                                        onChange={(e) => setState(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT STATE --</option>
                                                        {
                                                            states.map((state) => (
                                                                <option key={state.id} value={state.id}>{state.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                            </div>
                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {
                                            permissionModule.canDelete &&
                                            <div className="flex justify-start ">
                                                <button onClick={() => deleteCity(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                            </div>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Cities;
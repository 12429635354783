import React, { useEffect, useState } from "react";
import Chart from "../components/Chart";
import axios from "axios";
import SuperAgentData from "../components/SuperAgentData";
import KioskData from "../components/KioskData";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Sales = () => {

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    const [superagents, setSuperagents] = useState(0);
    const [kioksowners, setKioskowners] = useState(0);

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const navigate = useNavigate();


    useEffect(() => {


        const getSales = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/sales/get-sale", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                if (res.data.success) {
                    setTotalRevenue(res.data.data.totalSalesValue)
                    setTotalSales(res.data.data.totalQuantity);
                }


            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }

            }
        }

        getSales();

        const activeUsers = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/user/get-user?isSuperAgent=1&status=1", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const resp = await axios.get(process.env.REACT_APP_API_URL + "api/user/get-user?isSuperAgent=0&status=1", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });


                setSuperagents(res.data.data.pagination.totalRecords);
                setKioskowners(resp.data.data.pagination.totalRecords);
            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        activeUsers();
    }, []);
    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <div className="flex justify-between">
                    <h1 className="text-4xl text-black font-bold">Sales</h1>


                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5">
                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black  text-lg">Total Revenue</h1>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2" />
                                </svg>
                            </div>
                            <h1 className="text-xl text-black font-extrabold">₦{totalRevenue}</h1>
                        </div>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <a href="/add-kioskowner">
                            <div className="flex flex-col m-4 md:m-0">
                                <div className="flex justify-between">
                                    <h1 className="text-black text-lg">Active Kiosk Owners</h1>
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>

                                </div>
                                <h1 className="text-xl text-black font-extrabold">{kioksowners}</h1>

                            </div>
                        </a>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <a href="/add-superagent">
                            <div className="flex flex-col m-4 md:m-0">
                                <div className="flex justify-between">
                                    <h1 className="text-black text-lg">Active Super Agents</h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                                    </svg>

                                </div>
                                <h1 className="text-xl text-black font-extrabold">{superagents}</h1>

                            </div>
                        </a>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black text-lg">Scratch Cards sold</h1>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z" clipRule="evenodd" />
                                </svg>

                            </div>
                            <h1 className="text-xl text-black font-extrabold">{totalSales}</h1>
                        </div>
                    </div>


                    {/* Super Agents Section */}
                    <div className="md:col-span-2">
                        <SuperAgentData isKioskSales={false} />

                    </div>

                    {/* Kiosk Sales Section */}
                    <div className="md:col-span-2">
                        <KioskData />
                    </div>


                    {/* graph section */}
                    <div className="md:col-span-4 bg-white rounded-lg flex flex-col">
                        <h1 className="text-lg text-left font-bold m-4 flex" >Sales
                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                        </h1>
                        <div className=" w-full bg-white rounded-lg shadow dark:bg-gray-800">

                            <Chart />

                        </div>


                    </div>

                </div>


            </div>
        </div>
    )
}

export default Sales;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Terms = () => {
    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const navigate = useNavigate();

    const [refreshKey, setRefreshKey] = useState(0);
    
    const [content, setContent] = useState("");
    const [id, setId] = useState('');

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image'],
        ],
    };

    useEffect(() => {
        const getTerms = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/terms/get-terms", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setContent(res.data.terms[0].content);
                setId(res.data.terms[0].id);


            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        console.error(err.response.data); // Show other 400 errors
                    }
                } 
            }
        }
        getTerms();
    }, [refreshKey])

    // update single admin data
    const setTerms = async (e) => {
        e.preventDefault();
        const update_id = id;

        const postData = {
            "content": content,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/terms/update-terms/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);
            

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };
    

    return (
        <div className="bg-gray-100">
            <div className="py-20 p-4 sm:ml-64">

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-4xl md:text-xl text-black font-bold">Terms and Conditions</h1>
                        </div>

                    </div>

                    <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-white shadow-lg p-4">
                            <form onSubmit={setTerms}>
                                <h2 className="text-lg font-semibold mb-2">Edit Terms & Conditions</h2>
                                <ReactQuill modules={modules} theme="snow" value={content} onChange={setContent} />

                                <div className="flex space-x-5 mt-10">

                                    <button onClick={() => setTerms} className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center">Update</button>
                                    
                                </div>
                            </form>

                        </div>

                        <div className="bg-white shadow-lg p-4">

                            <h2 className="text-lg font-semibold">Preview</h2>
                            <div className="ql-editor  p-4 rounded-lg whitespace-pre-line" dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Terms;
import React, { createContext, useContext, useEffect, useState } from "react";

// Create Context
const AuthContext = createContext();

// Provider Component
export const AuthProvider = ({ children }) => {
    const [isSalesManager, setIsSalesManager] = useState(() => {
        // Load from localStorage when the app starts
        return localStorage.getItem("isSalesManager") === "true";
    });

    // Update localStorage whenever `isSalesManager` changes
    useEffect(() => {
        localStorage.setItem("isSalesManager", isSalesManager);
    }, [isSalesManager]);

    return (
        <AuthContext.Provider value={{ isSalesManager, setIsSalesManager }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom Hook to use Auth Context
export const useAuth = () => {
    return useContext(AuthContext);
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const SuperAgentData = ({ isKioskSales }) => {
    const location = useLocation();
    const isDashboard = location.pathname === "/";

    const navigate = useNavigate();
    const [superAgents, setSuperagents] = useState([]);
    const [isUsersFetched, setIsUsersFetched] = useState(false); // Track if users have been fetched

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    // Get "user" module permissions
    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    const getUsers = async () => {
        try {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            const userfilter = isKioskSales ? `isSuperAgent=0` : `isSuperAgent=1`;
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user?${queryParams}&${userfilter}&status=1`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const filteredUsers = res.data.data.users;

            setSuperagents(filteredUsers);
            setPagination({
                totalRecords: res.data.data.pagination.totalRecords,
                currentPage: res.data.data.pagination.currentPage,
                totalPages: res.data.data.pagination.totalPages,
                limit: res.data.data.pagination.limit,
            });

            setIsUsersFetched(true); // Mark users as fetched
        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    };

    const getSales = async () => {
        if (!isUsersFetched) return; // Ensure users are fetched before proceeding

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + "api/sales/get-sale", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const sales = res.data.data.sales;

            const updatedSuperAgents = superAgents.map(agent => {
                const agentSales = sales.filter(sale => sale.userId === agent.id || sale.agentId === agent.id);
                const totalSalesValue = agentSales.reduce(
                    (acc, sale) => acc + sale.totalSalesValue,
                    agent.totalSalesValue || 0
                );

                return { ...agent, totalSalesValue };
            });

            setSuperagents(updatedSuperAgents);
        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getUsers();
            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        };

        fetchData();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, [filters]); // Empty dependency array ensures this runs once on mount

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                await getSales();
            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        };

        if (isUsersFetched) {
            fetchSalesData();
        }
    }, [isUsersFetched,pagination]); // Runs when `isUsersFetched` changes // Depend on superAgents to re-run getSales() when it changes

    

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    return (
        <div className="w-full h-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    {isKioskSales ? 'Kiosk Owners' : 'Super Agents'}
                </h5>
                {isDashboard ?
                    <a
                        href="/add-superagent"
                        className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                        Add a Super Agent
                    </a> :
                    ""
                }

            </div>
            <div className="flow-root  overflow-y-auto" style={{ maxHeight: 'calc(5 * 4.5rem)' }}>
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">

                    {
                        permissionModule.canRead ? superAgents.map((superagent) => (


                            <li key={superagent.id} className="py-3 sm:py-4">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="w-8 h-8 rounded-full"
                                            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                                            alt="Neil image"
                                        />
                                    </div>
                                    <div className="flex-1  min-w-0 ms-4 text-left">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {superagent.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            {superagent.email}
                                        </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                        {/* ₦{superagent.totalSalesValue ? superagent.totalSalesValue : 0} */}

                                        {!isDashboard ? <a href={"/agent-profile/" + superagent.id}><p className="text-blue-600 text-md ml-3 underline">View</p></a> : ''}
                                    </div>
                                </div>
                            </li>
                        )) : "Access Denied"
                    }



                </ul>
            </div>
            {isDashboard ?
                <div className="flex justify-center mt-5">
                    <a href="/sales">
                        <p className="text-md text-blue-400">View All</p>
                    </a>
                </div> :

                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => handlePageChange(filters.page - 1)}
                        disabled={filters.page === 1}
                        className="text-white py-1 px-4 bg-black rounded-full mx-1"
                    >
                        Previous
                    </button>
                    <span className="px-4 py-2 mx-1 font-medium">
                        Page {pagination.currentPage} of {pagination.totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(filters.page + 1)}
                        disabled={filters.page === pagination.totalPages}
                        className="text-white py-1 px-4 bg-black rounded-full mx-1"
                    >
                        Next
                    </button>
                </div>
            }
        </div >
    );
}

export default SuperAgentData;

import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import Verify from './views/Verify';
import Sales from './views/Sales';
import Profile from './views/Profile';
import Claims from './views/Claims';
import Superagent from './views/Superagent';
import Batches from './views/Batches';
import Track from './views/Track';
import Admin from './views/Admin';
import Searchcard from './views/Searchcard';
import KioskSales from './views/KioskSales';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import Roles from './views/Roles';
import KioskOwners from './views/KioskOwners';
import Modules from './views/Modules';
import UploadTest from './views/UploadTest';
import States from './views/States';
import Cities from './views/Cities';
import { AuthProvider } from './components/AuthContext';
import Users from './views/Users';
import AllGames from './views/AllGames';
import Countries from './views/Countries';
import Faq from './views/Faq';
import Terms from './views/Terms';
import CountryGames from './views/CountryGames';
import Draw from './views/Draw';

function App() {

  const location = useLocation();
  const excludedRoutes = ['/login', '/verify'];


  return (
    <div className="App">
      <AuthProvider>
        {!excludedRoutes.includes(location.pathname) && <Navbar />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<Verify />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/agent-profile/:id" element={<Profile />} />
            <Route path="/claims" element={<Claims />} />
            <Route path="/add-superagent" element={<Superagent />} />
            <Route path="/add-batch" element={<Batches />} />
            <Route path="/track" element={<Track />} />
            <Route path="/admins" element={<Admin />} />
            <Route path="/search-card" element={<Searchcard />} />
            <Route path="/kiosk-sales" element={<KioskSales />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/add-kioskowner" element={<KioskOwners />} />
            <Route path="/modules" element={<Modules />} />
            <Route path="/upload" element={<UploadTest />} />
            <Route path="/states" element={<States />} />
            <Route path="/cities" element={<Cities />} />
            <Route path="/users" element={<Users />} />
            <Route path="/all-games" element={<AllGames />} />
            <Route path="/add-countries" element={<Countries />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/country-games/:id" element={<CountryGames />} />
            <Route path="/draw/:id" element={<Draw />} />

          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;

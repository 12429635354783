import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate, useParams } from "react-router-dom";

const Draw = () => {

    const params = useParams();

    const navigate = useNavigate();
    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const [winningNumbers, setWinningNumbers] = useState('');
    const [winners, setWinners] = useState([]);

    const [bestCounts, setBestCounts] = useState('');
    const [bestScore, setBestScore] = useState('');

    const matchCategories = ["sixMatch", "fiveMatch", "fourMatch", "threeMatch"];

    const [formData, setFormData] = useState({
        X: "",
        Y: "",
        Z: "",
        K: "",
    });

    // Handle input change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // Draw Using random number 
    const randomNumberDraw = async () => {
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/draw/random-draw?gameTypeId=${params.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setWinningNumbers(res.data.data.winningNumbers);
            setWinners(res.data.data.winners);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            }
        }
    }

    const queryDraw = async (e) => {
        e.preventDefault();

        // Construct query parameters
        const queryParams = new URLSearchParams({
            gameTypeId: params.id,
            X: formData.X,
            Y: formData.Y,
            Z: formData.Z,
            K: formData.K,
        }).toString();

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/draw/query-draw?${queryParams}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setWinningNumbers(res.data.data.bestSequence);
            setWinners(res.data.data.winners);
            setBestScore(res.data.data.bestScore);
            setBestCounts(res.data.data.bestCounts);
        } catch (err) {
            if (err.response?.status === 400) {
                if (err.response.data === "invalid token") {
                    navigate("/login");
                } else {
                    toast.error(err.response.data);
                }
            }
        }
    };


    const publishDraw = async () => {

        const postData = {
            "gameTypeId": params.id,
            "winningSequence": winningNumbers,
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/draw/publish`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            if(res.data.success){
                toast.success(res.data.message);
            }
        } catch (err) {
            if (err.response?.status === 400) {
                if (err.response.data === "invalid token") {
                    navigate("/login");
                } else {
                    console.error(err.response.data);
                }
            }
        }
    }

    return (
        <div className="bg-gray-100 h-screen">
            <div className="py-20 p-4 sm:ml-64">
                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <h1 className="text-2xl font-bold flex">Draw</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-5">

                        {/* Query details section */}
                        <div className="space-y-1 text-left ">
                            <h1 className=" font-normal text-black">Query Draw</h1>
                            <p className=" font-normal mt-5 text black">Use the inputs below to select:</p>
                            <p className=" font-normal mt-4 text black">- Game Type (weekly, morning, noon, evening)</p>
                            <p className=" font-normal text black">- State, Local Area</p>
                            <p className=" font-normal text black">- Desired distribution for 6-match (X), 5-match (Y), 4-match (Z), 3-match (K)</p>
                        </div>

                        {

                            <div className="md:flex justify-end items-center">
                                <button type="button"
                                    onClick={() => randomNumberDraw()}
                                    className="flex text-white bg-black rounded-full px-5 py-2 my-5">Draw Using Random Number</button>
                            </div>
                        }


                    </div>



                    <div className="mt-5">
                        <form onSubmit={queryDraw} className="mt-5">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                                {["X", "Y", "Z", "K"].map((field) => (
                                    <div key={field} className="space-y-3">
                                        <p>{field}:</p>
                                        <input
                                            type="number"
                                            name={field}
                                            value={formData[field]}
                                            onChange={handleChange}
                                            className="w-full mb-1 p-2 border border-gray-500 rounded"
                                            required
                                        />
                                    </div>
                                ))}

                                <div className="flex justify-start items-end">
                                    <button
                                        type="submit"
                                        className="text-white bg-black rounded-full text-sm px-5 py-2.5 text-center"
                                    >
                                        Query
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="mt-10">
                        <h2 className="text-2xl font-bold text-left mb-6">Query Draw</h2>

                        <p className="text-lg font-normal text-left mb-6">Generated Sequence: {winningNumbers ? winningNumbers.join(", ") : "N/A"}</p>
                        <p className="text-lg font-normal text-left mb-6">Best Score: {bestScore ? bestScore : "N/A"}</p>
                        <p className="text-lg font-normal text-left mb-6">Best Counts: {bestCounts ? Object.entries(bestCounts).map(([key, value]) => `${key}: ${value}`).join(', ') : "N/A"}</p>
                        <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">

                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 whitespace-normal break-words">Match Type</th>
                                        <th scope="col" className="px-6 py-3 whitespace-normal break-words">User Name</th>
                                        <th scope="col" className="px-6 py-3 whitespace-normal break-words">City</th>
                                        <th scope="col" className="px-6 py-3 whitespace-normal break-words">User Sequence</th>
                                        <th scope="col" className="px-6 py-3 whitespace-normal break-words"># of Matches</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {matchCategories.map((category) => {
                                        const categoryWinners = winners?.[category] || []; // Handle undefined case
                                        return categoryWinners.length > 0 ? (
                                            categoryWinners.map((winner, index) => (
                                                <tr key={`${category}-${index}`} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                    <td className="px-6 py-4 whitespace-normal break-words">{category}</td>
                                                    <td className="px-6 py-4 whitespace-normal break-words">{winner?.userName || "N/A"}</td>
                                                    <td className="px-6 py-4 whitespace-normal break-words">{winner?.city || "N/A"}</td>
                                                    <td className="px-6 py-4 whitespace-normal break-words">
                                                        {winner?.sequence ? winner.sequence.join(", ") : "N/A"}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-normal break-words">{winner?.matchCount || "N/A"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr key={category} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <td className="px-6 py-4 whitespace-normal break-words">{category}</td>
                                                <td className="px-6 py-4 whitespace-normal break-words" colSpan="5" align="center">
                                                    No winners
                                                </td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>

                        <div className="mt-5 md:flex justify-end md:space-x-7 space-x-3">
                            
                            <button
                                onClick={() => publishDraw()}
                                type="button"
                                className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                            >
                                Publish Random
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div
                id="popup-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Random Number Draw</h2>

                                <p className="text-lg font-normal text-left mb-6">Generated Sequence: {winningNumbers ? winningNumbers.join(", ") : "N/A"}</p>
                                <p className="text-lg font-normal text-left mb-6">Best Score: {bestScore ? bestScore : "N/A"}</p>

                                <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 whitespace-normal break-words">Match Type</th>
                                                <th scope="col" className="px-6 py-3 whitespace-normal break-words">User Name</th>
                                                <th scope="col" className="px-6 py-3 whitespace-normal break-words">City</th>
                                                <th scope="col" className="px-6 py-3 whitespace-normal break-words">User Sequence</th>
                                                <th scope="col" className="px-6 py-3 whitespace-normal break-words"># of Matches</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchCategories.map((category) => {
                                                const categoryWinners = winners?.[category] || []; // Handle undefined case
                                                return categoryWinners.length > 0 ? (
                                                    categoryWinners.map((winner, index) => (
                                                        <tr key={`${category}-${index}`} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <td className="px-6 py-4 whitespace-normal break-words">{category}</td>
                                                            <td className="px-6 py-4 whitespace-normal break-words">{winner?.userName || "N/A"}</td>
                                                            <td className="px-6 py-4 whitespace-normal break-words">{winner?.city || "N/A"}</td>
                                                            <td className="px-6 py-4 whitespace-normal break-words">
                                                                {winner?.sequence ? winner.sequence.join(", ") : "N/A"}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-normal break-words">{winner?.matchCount || "N/A"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr key={category} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <td className="px-6 py-4 whitespace-normal break-words">{category}</td>
                                                        <td className="px-6 py-4 whitespace-normal break-words" colSpan="5" align="center">
                                                            No winners
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-5 ">
                                    <button
                                        onClick={() => randomNumberDraw()}
                                        type="submit"
                                        className="text-white bg-black rounded-full  px-5 py-2.5 text-center"
                                    >
                                        Regenerate
                                    </button>
                                    <button
                                        onClick={() => publishDraw()}
                                        type="submit"
                                        className="text-white bg-black rounded-full  px-5 py-2.5 text-center"
                                    >
                                        Publish Random
                                    </button>
                                    <button
                                        onClick={() => {
                                            const createElement = document.getElementById("popup-modal");
                                            if (createElement) {
                                                const create_modal = new Modal(createElement);
                                                create_modal.hide();
                                            }
                                        }}
                                        type="button"
                                        className="py-2.5  text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Draw;
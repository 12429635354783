import React from "react";
import Chart from "../components/Chart";
import PrizeClaim from "../components/PrizeClaim";
import SuperAgentData from "../components/SuperAgentData";
import KioskData from "../components/KioskData";
import { useAuth } from "../components/AuthContext";


const Dashboard = () => {

    const { isSalesManager } = useAuth();

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 grid-flow-row">

                    {/* Sales Graph section */}
                    <div className="md:col-span-3 bg-white rounded-lg flex flex-col">
                        <h1 className="text-lg text-left font-bold m-4 flex" >Sales
                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                        </h1>
                        <div className=" w-full bg-white rounded-lg shadow dark:bg-gray-800">

                            <Chart />


                        </div>


                    </div>

                    {/* Super Agents Section */}
                    <div className="md:col-span-1">

                        <SuperAgentData />
                    </div>

                    {/* Kiosk Sales Section */}
                    <div className="md:col-span-2">
                        <KioskData />

                    </div>

                    {/* Prize claim verification */}

                    {
                        isSalesManager ? '' :
                            <div className="md:col-span-3">
                                <PrizeClaim />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
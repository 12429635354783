import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const AllGames = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [games, setGames] = useState([]);

    const navigate = useNavigate();


    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        gameType: "",
        isDrawPerformed: "",
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    useEffect(() => {
        const getGames = async () => {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/games/get-games?${queryParams}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const games = res.data.data.games;

                setGames(games);

                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });

            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Games Found.");
                }
            }
        }
        getGames();


        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'games'
            ));
        }
    }, [filters]);


    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };



    return (
        <div className="bg-gray-100">
            <div className="py-20 p-4 sm:ml-64">
                <div className="md:flex justify-start py-5">

                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                    <select
                        value={filters.gameType}
                        onChange={(e) => handleFilterChange("gameType", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    >
                        <option value="">All Games</option>
                        <option value="India">India</option>
                        <option value="Africa">Africa</option>
                        <option value="Nigeria">Nigeria</option>
                    </select>

                    <select
                        value={filters.isDrawPerformed}
                        onChange={(e) => handleFilterChange("isDrawPerformed", e.target.value)}
                        className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                    >
                        <option value="">All Draws</option>
                        <option value="1">Draw Performed</option>
                        <option value="0">Draw Not Performed</option>
                        
                    </select>

                    </div>
                </div>


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Games</h1>
                            <h1 className="text-md text-gray-400">Games Played by all Players</h1>
                        </div>

                    </div>

                    <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">User Name</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Country</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">City</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Game Level</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Game Type</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Credits</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Selected Numbers</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Is Winner</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Total Matching Number</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Prize Money</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Played On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {games.length > 0 ? (
                                    permissionModule.canRead ? games.map((game) => (
                                        <tr key={game.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-normal break-words dark:text-white">{game.User.name}</th>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.GameType.name}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game?.City?.name ? game?.City?.name + ', ' + game?.City?.State?.name + ', ' + game?.City?.State?.Country?.name : 'N/A'}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.gameLevel}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.typeOfGame}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.credits}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{(game.selectedNumbers).join(", ")}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.isWinner ? 'Yes' : 'No' }</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.matchingNumbers}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{game.prizeMoney}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{formatDate(game.createdAt)}</td>
                                            
                                        </tr>
                                    )) : "Access Denied"
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4 whitespace-normal break-words">No Games Found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>


                        {/* Pagination */}
                        <div className="flex justify-center my-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllGames;
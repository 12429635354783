import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Superagent = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [superAgents, setSuperagents] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [permissionRole, setPermissionRole] = useState('');
    const [roles, setRoles] = useState([]);
    const [id, setId] = useState('');
    const [citySearchTerm, setCitySearchTerm] = useState('');
    const [salesManagerSearchTerm, setSalesManagerSearchTerm] = useState('');

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');

    const [salesManagers, setSalesManagers] = useState([]);
    const [salesManager, setSalesManager] = useState('');

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({

        isSuperAgent: 1,
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    // create admin
    const handleCreateSuperagent = async (e) => {
        e.preventDefault();

        const serial_number = serialNumber.split(",")
            .map((item) => item.trim())
            .filter(Boolean) // Remove empty strings
            .map((item) => parseInt(item, 10)) // Convert to integers
            .filter((item) => !isNaN(item));

        
        const postData = {
            "email": email,
            "password": password,
            "phone": contact,
            "name": fullname,
            "cityId": city,
            "isSuperAgent": true,
            "serialNumber": serial_number,
            "ModelNumber": modelNumber,
            "roleId": permissionRole,
            "salesManagerId": salesManager,
        };
        
        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/user/create-user", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);

            // Copy both email and password to the clipboard
            const clipboardText = `Email: ${email}\nPassword: ${password}`;
            navigator.clipboard
                .writeText(clipboardText)
                .then(() => {
                    toast.success("Copied to Clipboard.")
                })
                .catch((err) => {
                    console.error('Failed to copy email and password to clipboard:', err);
                });

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    useEffect(() => {
        const getSuperagents = async () => {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user?${queryParams}&isSuperAgent=1`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const filteredUsers = res.data.data.users;
                setSuperagents(filteredUsers);

                setPagination({
                    totalRecords: res.data.data.pagination.totalRecords,
                    currentPage: res.data.data.pagination.currentPage,
                    totalPages: res.data.data.pagination.totalPages,
                    limit: res.data.data.pagination.limit,
                });
            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("No Users found.");
                }
            }
        }
        getSuperagents();

        const getRoles = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/role/get-role", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setRoles(res.data.roles);


            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getRoles();


        // get cities
        const getCities = async (e) => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/state-city/cities`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setCities(res.data.data);

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getCities();

        // get Sales Manager
        const getManagers = async (e) => {
            try {
                const sales_role = roles.find(role => role.roleName === 'Sales Manager')
                
                const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin/admin-users/get-admin-user?roleId=${sales_role.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setSalesManagers(res.data.data.adminUsers);

            } catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getManagers();


        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, [refreshKey, filters]);

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const viewElement = document.getElementById("view-modal");
        if (createElement) {
            const view_modal = new Modal(viewElement);
            view_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getUserData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setId(id);
            setFullname(res.data.user.name);
            setEmail(res.data.user.email);
            setCity(res.data.user.City.id);
            setContact(res.data.user.phone);
            setModelNumber(res.data.user.ModelNumber);
            setSalesManager(res.data.user.salesManagerId);
            setSerialNumber(res.data.user.serialNumber).join(', ');

            setPermissionRole(res.data.user.roleId);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateUser = async (e) => {
        e.preventDefault();
        const update_id = id;

        const serial_number = serialNumber.split(",")
            .map((item) => item.trim())
            .filter(Boolean) // Remove empty strings
            .map((item) => parseInt(item, 10)) // Convert to integers
            .filter((item) => !isNaN(item));

        const postData = {
            "name": fullname,
            "email": email,
            "cityId": city,
            "phone": contact,
            "serialNumber": serial_number,
            "ModelNumber": modelNumber,
            "roleId": permissionRole,
            "salesManagerId": salesManager,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/user/update-user/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteUser = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/user/delete-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            toast.success(res.data.message);



        } catch (err) {
            console.error(err);
        }
    }

    // Function to generate a random password
    const generatePassword = useCallback(() => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const passwordLength = 8;
        let newPassword = '';

        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            newPassword += characters[randomIndex];
        }

        setPassword(newPassword);

    }, []);

    // Generate a password on the first render
    useEffect(() => {
        generatePassword();
    }, [generatePassword]);

    const updatePassword = async (id, email_id) => {

        generatePassword();
        // console.log(password);

        const postData = {
            "password": password
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/user/update-user/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);

            const clipboardText = `Email: ${email_id}\nPassword: ${password}`;
            navigator.clipboard
                .writeText(clipboardText)
                .then(() => {
                    toast.success("Copied to Clipboard.")
                })
                .catch((err) => {
                    console.error('Failed to copy email and password to clipboard:', err);
                });


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    }

    //  update status of user:
    const updateStatus = async (id, status) => {
        const postData = {
            "status": status,
        }
        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/user/update-user/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);

        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    const showSerialNumber = (serial_number) => {
        const modalElement = document.getElementById("view-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        if (serial_number) {
            document.getElementById("serial-number").innerText = serial_number.join(', ');
        } else {
            document.getElementById("serial-number").innerText = "No Serial Number found.";
        }
    }


    // Handle search functionality
    const handleCitySearch = async (search) => {
        setCitySearchTerm(search);


        try {

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/state-city/cities?search=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setCities(res.data.data);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };

    // Handle search functionality
    const handleManagerSearch = async (search) => {
        setSalesManagerSearchTerm(search);


        try {
            const sales_role = roles.find(role => role.roleName === 'Sales Manager')

            const res = await axios.get(process.env.REACT_APP_API_URL + `api/admin/admin-users/get-admin-user?roleId=${sales_role.id}&name=${search}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setSalesManagers(res.data.data.adminUsers);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not found");
            }
        }

    };

    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
            page: 1, // Reset to the first page on filter change
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">

                <div className="md:flex justify-between">
                    {
                        permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add Super Agent</button>
                    }


                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Name"
                            value={filters.name}
                            onChange={(e) => handleFilterChange("name", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <input
                            type="text"
                            placeholder="Search by Email"
                            value={filters.email}
                            onChange={(e) => handleFilterChange("email", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <input
                            type="text"
                            placeholder="Search by Phone"
                            value={filters.phone}
                            onChange={(e) => handleFilterChange("phone", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />
                        <select
                            value={filters.status}
                            onChange={(e) => handleFilterChange("status", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        >
                            <option value="">All Statuses</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>

                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Super Agents</h1>
                            <h1 className="text-md text-gray-400">This is a list of latest super agents</h1>
                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Model Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Location
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        View Serial Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Toggle Status
                                    </th>
                                    {
                                        permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                    }
                                    {
                                        permissionModule.canUpdate &&
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    superAgents.length > 0 ? (
                                        permissionModule.canRead ? superAgents.map((superagent) => (
                                            <tr key={superagent.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {superagent.name}
                                                </th>

                                                <td className="px-6 py-4">{superagent.ModelNumber}</td>
                                                <td className="px-6 py-4">{formatDate(superagent.createdAt)}</td>
                                                <td className="px-6 py-4">{superagent.City ? (superagent.City.name + ', ' + superagent.City.State.name) :"" }</td>
                                                <td className="px-6 py-4"><button type="button" onClick={() => showSerialNumber(superagent.serialNumber)} className="bg-blue-600 text-white rounded-full px-3 py-1">View</button></td>
                                                <td className="px-6 py-4">
                                                    {superagent.status === 1 ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-full text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-full text-center">Inactive</p>}
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        superagent.status === 1 ? <button type="button" onClick={() => updateStatus(superagent.id, 0)} className="bg-red-600 text-white px-3 py-1 rounded-full">Disable</button>
                                                            : <button type="button" onClick={() => updateStatus(superagent.id, 1)} className="bg-green-600 text-white px-3 py-1 rounded-full">Enable</button>
                                                    }
                                                </td>
                                                {
                                                    permissionModule.canUpdate &&
                                                    <td className="px-6 py-4">
                                                        <button type="button" onClick={() => getUserData(superagent.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                                    </td>
                                                }
                                                {
                                                    permissionModule.canUpdate ?
                                                        <td className="px-6 py-4">
                                                            <button type="button" onClick={() => updatePassword(superagent.id, superagent.email)} className="text-white py-2 px-4 bg-blue-800 rounded-full">Generate Password</button>
                                                        </td> : ""
                                                }

                                            </tr>
                                        )) : "Access Denied") :
                                        (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No users found.
                                                </td>
                                            </tr>
                                        )
                                }

                            </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Add Super Agent</h2>
                                        <form onSubmit={handleCreateSuperagent}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Email</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="email"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Contact Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="number"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Serial Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="[87418, 871987, 98797, 9897]"
                                                        value={serialNumber}
                                                        onChange={(e) => setSerialNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Model Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="ABC1234"
                                                        value={modelNumber}
                                                        onChange={(e) => setModelNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Password</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="*********"
                                                        value={password}
                                                        disabled
                                                    />
                                                    <button type="button" onClick={generatePassword} className="text-white bg-blue-700 rounded-lg ml-5 p-2">Generate</button>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select City</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search city"
                                                        value={citySearchTerm}
                                                        onChange={(e) => handleCitySearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT CITY --</option>
                                                        {
                                                            cities.map((city) => (
                                                                <option key={city.id} value={city.id}>{city.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Sales Manager</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Sales Manager"
                                                        value={salesManagerSearchTerm}
                                                        onChange={(e) => handleManagerSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={salesManager}
                                                        onChange={(e) => setSalesManager(e.target.value)}
                                                        required
                                                    >
                                                        
                                                        
                                                        <option value="" disabled>-- SELECT SALES MANAGER --</option>
                                                        {
                                                            salesManagers.map((manager) => (
                                                                <option key={manager.id} value={manager.id}>{manager.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Role</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select
                                                        id="batchId"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>



                                            <div className="mt-5 flex justify-between">

                                                {/* <div>
                                                    <button className="bg-red-600 text-white px-5 py-2 rounded-full">Delete User</button>
                                                </div> */}

                                                <div>

                                                    <button

                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit Super Agent</h2>
                                        <form onSubmit={handleUpdateUser}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Email</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="email"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Contact Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="number"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder={30000}
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Serial Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="[87418, 871987, 98797, 9897]"
                                                        value={serialNumber}
                                                        onChange={(e) => setSerialNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Model Number</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="ABC1234"
                                                        value={modelNumber}
                                                        onChange={(e) => setModelNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select City</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search city"
                                                        value={citySearchTerm}
                                                        onChange={(e) => handleCitySearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT CITY --</option>
                                                        {
                                                            cities.map((city) => (
                                                                <option key={city.id} value={city.id}>{city.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Sales Manager</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Sales Manager"
                                                        value={salesManagerSearchTerm}
                                                        onChange={(e) => handleManagerSearch(e.target.value)}
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    <select
                                                        id="user"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={salesManager}
                                                        onChange={(e) => setSalesManager(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>-- SELECT SALES MANAGER --</option>
                                                        {
                                                            salesManagers.map((manager) => (
                                                                <option key={manager.id} value={manager.id}>{manager.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Select Role</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select
                                                        id="batchId"
                                                        className="w-3/4 mb-1 p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" disabled>--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                            </div>

                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {
                                            permissionModule.canDelete &&

                                            <div className="flex justify-start ">
                                                <button onClick={() => deleteUser(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                            </div>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* view modal */}
                    <div
                        id="view-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("view-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Serial Number</h2>
                                        <p className="text-xl font-medium m-5" id="serial-number"></p>
                                    </div>

                                    <button
                                        onClick={() => {
                                            const modalElement = document.getElementById("view-modal");
                                            if (modalElement) {
                                                const modal = new Modal(modalElement);
                                                modal.hide();
                                            }
                                        }}
                                        type="button"
                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                    >
                                        Cancel
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Superagent;
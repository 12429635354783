import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";

const Countries = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [countries, setCountries] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);

    const [name, setName] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState([]);

    const [id, setId] = useState('');
    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);




    // create country
    const handleCreateCountry = async (e) => {
        e.preventDefault();

        const postData = {
            "name": name,
            "countryCode": countryCode,
            "currencySymbol": currencySymbol
        };


        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "api/Country/create-country", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };


    useEffect(() => {
        const getCountries = async () => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + "api/Country/get-countries", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                if(res.data.countries){

                    setCountries(res.data.countries);
                }else{
                    setCountries([]);
                }


            }
            catch (err) {
                if (err.response?.status === 400) {
                    // Handle invalid token error
                    if (err.response.data === "invalid token") {
                        navigate("/login"); // Redirect to login page
                    } else {
                        toast.error(err.response.data); // Show other 400 errors
                    }
                } else {
                    // Handle other errors
                    toast.error("An unexpected error occurred.");
                }
            }
        }
        getCountries();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'Country'
            ));
        }

    }, [refreshKey]);


    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }


    }, []);




    // get single admin data
    const getCountryData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/Country/get-country/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            setName(res.data.country.name);
            setCountryCode(res.data.country.countryCode);
            setCurrencySymbol(res.data.country.currencySymbol);
            setId(id);


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // update single admin data
    const handleUpdateCountry = async (e) => {
        e.preventDefault();
        const update_id = id;


        const postData = {
            "name": name,
            "countryCode": countryCode,
            "currencySymbol": currencySymbol,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL + `api/Country/update-country/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);
            toast.success(res.data.message);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }

    };

    const deleteCountry = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/Country/delete-country/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            toast.success(res.data.message);
            setRefreshKey((prevKey) => prevKey + 1);




        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }

    return (
        <div className="bg-gray-100 h-screen">
            <div className="py-20 p-4 sm:ml-64">
                <div className="md:flex justify-between py-5">

                    {
                        // permissionModule.canCreate &&
                        <button type="button"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.show();
                                }
                            }}
                            className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add New Country</button>
                    }

                    {/* Filters UI */}
                    <div className=" items-center md:flex gap-4">
                        <input
                            type="text"
                            placeholder="Search by Name"
                            // value={filters.name}
                            // onChange={(e) => handleFilterChange("name", e.target.value)}
                            className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                        />

                    </div>
                </div>


                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Countries</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto custom-scrollbar sm:rounded-lg mt-5">

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Country Name</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Extension Code</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Currency Symbol</th>
                                    <th scope="col" className="px-6 py-3 whitespace-normal break-words">Games</th>
                                    { permissionModule.canUpdate && <th scope="col" className="px-6 py-3 whitespace-normal break-words">Edit</th>}

                                </tr>
                            </thead>
                            <tbody>
                                {countries.length > 0 ? (
                                    permissionModule.canRead ? countries.map((country) => (
                                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">

                                            <td className="px-6 py-4 whitespace-normal break-words">{country.name}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{country.countryCode}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words">{country.currencySymbol}</td>
                                            <td className="px-6 py-4 whitespace-normal break-words"><a href={"/country-games/" + country.id} className="bg-blue-900  text-white rounded-full px-3 py-1">Games</a></td>
                                            { permissionModule.canUpdate && <td className="px-6 py-4 whitespace-normal break-words"><button type="button" onClick={() => getCountryData(country.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button></td>}
                                            

                                        </tr>

                                    )) : "Access Denied"
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4 whitespace-normal break-words">No Countries Found.</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>


                        {/* Pagination */}
                        {/* <div className="flex justify-center my-4">
                            <button
                                onClick={() => handlePageChange(filters.page - 1)}
                                disabled={filters.page === 1}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2 mx-1 font-medium">
                                Page {pagination.currentPage} of {pagination.totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(filters.page + 1)}
                                disabled={filters.page === pagination.totalPages}
                                className="text-white py-1 px-4 bg-black rounded-full mx-1"
                            >
                                Next
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div
                id="popup-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const createElement = document.getElementById("popup-modal");
                                if (createElement) {
                                    const create_modal = new Modal(createElement);
                                    create_modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Add New Country</h2>
                                <form onSubmit={handleCreateCountry}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Country Name</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Extension Code</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={countryCode}
                                                onChange={(e) => setCountryCode(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Currency Symbol</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={currencySymbol}
                                                onChange={(e) => setCurrencySymbol(e.target.value)}
                                                required
                                            />
                                        </div>

                                    </div>



                                    <div className="mt-5 flex justify-between">



                                        <div>

                                            <button

                                                type="submit"
                                                className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const createElement = document.getElementById("popup-modal");
                                                    if (createElement) {
                                                        const create_modal = new Modal(createElement);
                                                        create_modal.hide();
                                                    }
                                                }}
                                                type="button"
                                                className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            {/* edit modal */}
            <div
                id="edit-modal"
                tabIndex={-1}
                className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => {
                                const modalElement = document.getElementById("edit-modal");
                                if (modalElement) {
                                    const modal = new Modal(modalElement);
                                    modal.hide();
                                }
                            }}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4  text-center">
                            <div className=" p-6 bg-white shadow rounded-lg">
                                <h2 className="text-2xl font-bold text-center mb-6">Edit Country</h2>
                                <form onSubmit={handleUpdateCountry}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Country Name</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Extension Code</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={countryCode}
                                                onChange={(e) => setCountryCode(e.target.value)}
                                                required
                                            />
                                        </div>



                                        <div className="md:col-span-1">
                                            <label className="block mb-1 font-medium">Currency Symbol</label>
                                        </div>
                                        <div className="md:col-span-2">
                                            <input
                                                type="text"
                                                className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                placeholder=""
                                                value={currencySymbol}
                                                onChange={(e) => setCurrencySymbol(e.target.value)}
                                                required
                                            />
                                        </div>

                                    </div>

                                    <div className="mt-5 flex justify-end">



                                        <div>

                                            <button
                                                type="submit"
                                                className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const modalElement = document.getElementById("edit-modal");
                                                    if (modalElement) {
                                                        const modal = new Modal(modalElement);
                                                        modal.hide();
                                                    }
                                                }}
                                                type="button"
                                                className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {
                                    permissionModule.canDelete &&
                                    <div className="flex justify-start ">
                                        <button onClick={() => deleteCountry(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                    </div>
                                }

                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Countries;
import React, { useState, useEffect } from "react";
import avatar from '../images/avatar.png';
import Colchart from "../components/Colchart";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from 'react-hot-toast'
import { useNavigate } from "react-router-dom";


const Profile = () => {
    const params = useParams();

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const [user, setUser] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [recentSales, setRecentsales] = useState([]);
    const [kioskOwners, setKioskowners] = useState([]);
    const [isKioskOwner, setIsKioskOwner] = useState(false);

    const navigate = useNavigate();

    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const [permissionModule, setPermissionModule] = useState([]);

    // pagination and search
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10,
    });
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });

    const [salefilters, setSaleFilters] = useState({
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
    });
    const [salepagination, setSalePagination] = useState({
        totalRecords: 0,
        currentPage: 1,
        totalPages: 0,
        limit: 0,
    });


    const getUser = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user/${params.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setUser(res.data.user);
            if (res.data.user.isSuperAgent !== 1) {
                setIsKioskOwner(true);
            }


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("User not Found.");
            }
        }
    };

    const getSales = async () => {

        try {
            const queryParams = isKioskOwner ? `userId=${params.id}` : `userId=${params.id}&agentId=${params.id}`;
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/sales/profile-sale?${queryParams}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const totalQuantity = res.data.sales[0].totalQuantity;

            setQuantity(totalQuantity);



        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            }
        }
    };
    // get recent sales
    const getRecentSales = async () => {
        try {
            const queryParams = Object.entries(salefilters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            const users = isKioskOwner ? `userId=${params.id}` : `agentId=${params.id}`;
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/sales/get-sale?${queryParams}&${users}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });


            const filteredUsers = res.data.data.sales;
            setRecentsales(filteredUsers);

            setSalePagination({
                totalRecords: res.data.data.pagination.totalRecords,
                currentPage: res.data.data.pagination.currentPage,
                totalPages: res.data.data.pagination.totalPages,
                limit: res.data.data.pagination.limit,
            });




        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            }
        }
    }

    // kiosk owner under him
    const getKioskOwners = async () => {
        try {
            const queryParams = Object.entries(filters)
                .filter(([_, value]) => value) // Only include parameters with non-empty values
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            const res = await axios.get(process.env.REACT_APP_API_URL + `api/user/get-user?referredSuperAgent=${params.id}&${queryParams}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const filteredUsers = res.data.data.users;
            if (filteredUsers.length > 0) {
                setKioskowners(filteredUsers);
            }
            else {
                setKioskowners([]);
            }

            setPagination({
                totalRecords: res.data.data.pagination.totalRecords,
                currentPage: res.data.data.pagination.currentPage,
                totalPages: res.data.data.pagination.totalPages,
                limit: res.data.data.pagination.limit,
            });


        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            }
        }
    }

    useEffect(() => {
        getUser();
        getSales();
        getRecentSales();
        getKioskOwners();
        if (permissions) {

            setPermissionModule(permissions.find(
                (permission) => permission.Module.moduleName === 'user'
            ));
        }
    }, [filters, salefilters])

    const deleteUser = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL + `api/user/delete-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            toast.success(res.data.message);




        } catch (err) {
            if (err.response?.status === 400) {
                // Handle invalid token error
                if (err.response.data === "invalid token") {
                    navigate("/login"); // Redirect to login page
                } else {
                    toast.error(err.response.data); // Show other 400 errors
                }
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
        }
    }


    // search and pagination
    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSaleFilterChange = (key, value) => {
        setSaleFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    const handleSalePageChange = (newPage) => {
        setSaleFilters((prev) => ({ ...prev, page: newPage }));
    };

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <div className="md:flex justify-between">
                    <a href={isKioskOwner ? '/kiosk-sales' : '/sales'} className="text-black flex text-lg">&lt; Back to sales</a>
                    {/* Filters UI */}
                    {
                        isKioskOwner ?
                            <div className="md:flex items-center gap-4">

                                <p className="flex">Start Date:</p>
                                <input
                                    type="date"
                                    placeholder="Search by Start Date"
                                    value={salefilters.startDate}
                                    onChange={(e) => handleSaleFilterChange("startDate", e.target.value)}
                                    className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                                />
                                <p className="flex">End Date:</p>
                                <input
                                    type="date"
                                    placeholder="Search by End Date"
                                    value={salefilters.endDate}
                                    onChange={(e) => handleSaleFilterChange("endDate", e.target.value)}
                                    className="my-1 md:my-0 flex border-2 rounded-full px-3 py-2"
                                />

                            </div>
                            : ""
                    }
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                    {/* Super Agent Profile */}
                    <div className="md:col-span-2 md:flex items-center justify-between bg-white shadow-md rounded-lg p-10 mt-5">
                        {/* User Information */}
                        <div className="md:flex items-center space-x-4">
                            {/* User Image */}
                            <img
                                src={avatar}
                                alt="User Avatar"
                                className=" rounded-full object-cover"
                            />
                            {/* User Details */}
                            <div className="text-left">
                                <h2 className="text-xl font-semibold text-black">{user.name}</h2>
                                <p className="text-lg text-black">{isKioskOwner ? 'Kiosk Owner' : 'Super Agent'}</p>
                                {/* <p className="text-md text-gray-500">234 Kiosk Owner under him</p> */}
                                <p className="text-md text-gray-500">
                                    {user.email} • {user.phone}
                                </p>
                                <div className="flex items-baseline space-x-1 mt-2">
                                    <span className="text-lg text-black">Total sales</span>
                                    <span className="text-2xl font-bold text-gray-800">{quantity}</span>
                                    <span className="text-lg text-gray-500">sales</span>
                                </div>
                            </div>
                        </div>
                        {/* Action Button */}
                        {
                            permissionModule.canDelete &&
                            <button data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="mt-5 md:mt-0 bg-black text-white text-sm px-4 py-2 rounded-full shadow hover:bg-gray-800 transition duration-200" type="button">
                                Remove User
                            </button>
                        }
                    </div>

                    {/* Bar chart */}
                    <div className="md:col-span-2 flex flex-col text-left p-5 bg-white shadow-md rounded-lg">
                        <h1 className="text-xl text-black font-bold">Overview</h1>

                        {
                            isKioskOwner ?
                                <Colchart id={params.id} isKioskOwner={true} /> :
                                <Colchart id={params.id} isKioskOwner={false} />
                        }
                    </div>

                    {/* Recent Sales section */}
                    {
                        isKioskOwner ?
                            <div className="md:col-span-2">
                                <div className="w-full h-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                                    <div className="flex flex-col mb-4 text-left">
                                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                                            Recent Sales
                                        </h5>

                                        <h1 className="text-md text-gray-400">You made {recentSales.length} sales this month.</h1>
                                    </div>
                                    <div className="flow-root">
                                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                            {
                                                recentSales.length > 0 ? (
                                                    recentSales.map((recentSale) => (

                                                        <li key={recentSale.id} className="py-3 sm:py-4">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    <img
                                                                        className="w-8 h-8 rounded-full"
                                                                        src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                                                                        alt="Neil image"
                                                                    />
                                                                </div>
                                                                <div className="flex-1  min-w-0 ms-4 text-left">
                                                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                        {recentSale.User.name}
                                                                    </p>
                                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                                        {recentSale.User.email}
                                                                    </p>
                                                                </div>
                                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                    ₦{recentSale.totalSalesValue}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))) : <p className="text-gray-500 font-medium">No Sales found.</p>
                                            }

                                        </ul>

                                        <div className="flex justify-center mt-4">
                                            <button
                                                onClick={() => handleSalePageChange(salefilters.page - 1)}
                                                disabled={salefilters.page === 1}
                                                className="text-white py-1 px-4 bg-black rounded-full "
                                            >
                                                Previous
                                            </button>
                                            <span className="px-4 py-2 mx-1 font-medium">
                                                Page {salepagination.currentPage} of {salepagination.totalPages}
                                            </span>
                                            <button
                                                onClick={() => handleSalePageChange(salefilters.page + 1)}
                                                disabled={salefilters.page === salepagination.totalPages}
                                                className="text-white py-1 px-4 bg-black rounded-full "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div> : ""
                    }

                    {/* kiosk owners under him section */}
                    {
                        isKioskOwner ? '' :
                            <div className="md:col-span-2">
                                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                                    <div className="flex items-start justify-between mb-4">
                                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                                            Kiosk Owners under him
                                        </h5>
                                    </div>
                                    <div className="flow-root">
                                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">

                                            {kioskOwners.length > 0 ? (

                                                kioskOwners.map((kioskOwner) => (

                                                    <li className="py-3 sm:py-4">
                                                        <div className="flex items-center ">
                                                            <a href={"/agent-profile/" + kioskOwner.id}>
                                                                <div className="flex-1 min-w-0 ms-4 text-left">
                                                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                        {kioskOwner.name}
                                                                    </p>
                                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                                        {kioskOwner.email}
                                                                    </p>
                                                                </div>
                                                            </a>

                                                        </div>
                                                    </li>
                                                ))
                                            ) : <p className="text-gray-500 font-medium">No Kiosk Owners found</p>
                                            }

                                        </ul>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={() => handlePageChange(filters.page - 1)}
                                            disabled={filters.page === 1}
                                            className="text-white py-1 px-4 bg-black rounded-full"
                                        >
                                            Previous
                                        </button>
                                        <span className="px-4 py-2 mx-1 font-medium">
                                            Page {pagination.currentPage} of {pagination.totalPages}
                                        </span>
                                        <button
                                            onClick={() => handlePageChange(filters.page + 1)}
                                            disabled={filters.page === pagination.totalPages}
                                            className="text-white py-1 px-4 bg-black rounded-full"
                                        >
                                            Next
                                        </button>
                                    </div>

                                </div>

                            </div>

                    }

                </div>

                <div
                    id="popup-modal"
                    tabIndex={-1}
                    className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="popup-modal"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-10 text-center">
                                <h1 className="text-xl text-black">Delete User</h1>
                                <h3 className="my-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Do you like to delete this user?
                                </h3>
                                <button
                                    data-modal-hide="popup-modal"
                                    type="submit"
                                    onClick={() => deleteUser(user.id)}
                                    className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    Delete
                                </button>
                                <button
                                    data-modal-hide="popup-modal"
                                    type="button"
                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Profile;